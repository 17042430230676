






















  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { MappedAttributeMultiCheckboxFilter } from '@one/types'
  import { MultiCheckboxFilterChangeEvent } from '~/utils/ProductsListing'

  @Component({
    components: {
      OneMulticheckboxFilterItem: () =>
        import('../../atoms/multicheckboxes/OneMulticheckboxFilterItem.vue'),
    },
  })
  export default class OneMultiCheckboxFilter extends Vue {
    name: string = 'one-multicheckbox-filter'

    @Prop({
      required: true,
      type: Object,
    })
    filter!: MappedAttributeMultiCheckboxFilter

    @Prop({
      required: true,
      type: String,
    })
    testId!: string

    isCollapsed: boolean = true

    get items(): Array<string> {
      return this.sort(Object.values(this.filter.items)).map(
        (filter: { valueCode: string }) => filter.valueCode,
      )
    }

    sort(array: any) {
      return array.sort((a: { label: string }, b: { label: string }) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
    }

    get filterUnit(): string | null {
      return this.filter.unit ? `[${this.filter.unit}]` : null
    }

    isDisabled(filterValueCode: string) {
      return this.filter.items[filterValueCode].count < 1
    }

    getValueText(filterValueCode: string): string {
      return `${this.filter.items[filterValueCode].label} (${this.filter.items[filterValueCode].count})`
    }

    isValueSelected(filterValueCode: string): boolean {
      return this.filter.items[filterValueCode].selected
    }

    filterValueChanged(filterValueCode: string, isSelected: boolean) {
      this.$emit('input', {
        filterValue: filterValueCode,
        isSelected: isSelected,
      } as MultiCheckboxFilterChangeEvent)
    }

    onCollapseToggle() {
      this.isCollapsed = !this.isCollapsed
      this.$emit('isCollapsed', this.isCollapsed)
    }
  }
